// extracted by mini-css-extract-plugin
export var ArtistDescription = "SamFrancis-module--ArtistDescription--XE0X2";
export var ButtonWrapper = "SamFrancis-module--ButtonWrapper --q6pmh";
export var CardWrapper = "SamFrancis-module--CardWrapper--Gg1pY";
export var CarrouselWrapper = "SamFrancis-module--CarrouselWrapper--g98wR";
export var CarrouselWrapper2 = "SamFrancis-module--CarrouselWrapper2--NXfdz";
export var DescriptionWrapper = "SamFrancis-module--DescriptionWrapper--5mU1w";
export var ExpoWrapper = "SamFrancis-module--ExpoWrapper--J5wBC";
export var LinkWrapper = "SamFrancis-module--LinkWrapper--s2SZN";
export var NameWrapper = "SamFrancis-module--NameWrapper--TiTkN";
export var PdpWrapper = "SamFrancis-module--PdpWrapper--tnDcd";
export var PhotosWrapper = "SamFrancis-module--PhotosWrapper--H8WQq";
export var ProfilWrapper = "SamFrancis-module--ProfilWrapper--BhNvm";
export var TitleWrapper = "SamFrancis-module--TitleWrapper--V-Nfp";
export var VideoWrapper = "SamFrancis-module--VideoWrapper--RyN4A";
export var VideosWrapper = "SamFrancis-module--VideosWrapper--Or7nd";
export var WorkWrapper = "SamFrancis-module--WorkWrapper--MzBI5";
export var WorksWrapper = "SamFrancis-module--WorksWrapper--xy3U9";
export var Wrapper = "SamFrancis-module--Wrapper--gHXCT";